<template>
  <v-row class="personal-data-details mt-6 mt-md-12">
    <v-col class="personal-data-details__menu" cols="12" sm="4">
      <Sidebar
        :rootMenu="'personal-data'"
        :items="menuItems"
        :itemChanged="handleSelectedItemChanged"
      />
    </v-col>
    <v-col class="personal-data-details__content" cols="12" sm="8">
      <CardItem
        id="data-privacy"
        :title="$t(`support.items.personal-data.menu.data-privacy.text`)"
      >
        <p class="mb-4">
          {{
            $t("support.items.personal-data.menu.data-privacy.content.line-1")
          }}:
        </p>
      </CardItem>
      <CardItem
        id="security-updates"
        :title="$t(`support.items.personal-data.menu.security-updates.text`)"
      >
        <p class="mb-4">
          {{
            $t(
              "support.items.personal-data.menu.security-updates.content.line-1"
            )
          }}:
        </p>
      </CardItem>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    Sidebar: () => import("../Shared/Sidebar.vue"),
    CardItem: () => import("../Shared/CardItem.vue"),
  },
  data(vm) {
    return {
      menuItems: [
        {
          key: "data-privacy",
          value: vm.$t("support.items.personal-data.menu.data-privacy.text"),
        },
        {
          key: "security-updates",
          value: vm.$t(
            "support.items.personal-data.menu.security-updates.text"
          ),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  watch: {
    searchParams: {
      handler({ criteria }) {
        if (criteria) {
          const menuItem = this.menuItems.find(
            (item) => item.value === criteria
          );

          if (menuItem) this.handleSelectedItemChanged(menuItem.key);
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleSelectedItemChanged(key) {
      this.$vuetify.goTo(`#${key}`);
    },
  },
};
</script>
